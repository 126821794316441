import "core-js/modules/es.array.push.js";
import { login, sendCode } from "../../api/application/index";
import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import '../../utils/jquery-3.3.1.min.js';
import '../../utils/jquery.cookie.js';
import oha_api from '../../utils/oha_api';
export default {
  data() {
    return {
      phone: '',
      checked: false,
      code: '',
      time: 60,
      timedemo: null,
      codeyes: false
    };
  },
  methods: {
    //登录
    add() {
      if (!this.checked) {
        $Toast('请先阅读并同意用户隐私政策');
        return;
      }
      if (this.phone.length != 11) {
        $Toast('请输入正确手机号');
        return;
      }
      if (this.code == '') {
        $Toast('验证码不能为空');
        return;
      }
      login({
        code: this.code,
        phone: this.phone
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          console.log(res.data);
          localStorage.setItem('dzmmtoken', res.data);
          oha_api.cookieWrite('userinfo', res.data);
          sessionStorage.setItem('dzmmtoken', res.data);
          //     console.log('2222222',oha_api.cookieRead('userinfo','1'));
          //  if(oha_api.cookieRead('userinfo','1') != 1){
          //   localStorage.setItem('dzmmtoken',oha_api.cookieRead("userinfo", "1"))
          $Toast('登录成功');
          this.$router.replace({
            path: '/api/index/default.html'
          });
          //  }
        }
      });
    },

    goregister() {
      this.$router.push({
        path: '/zhuce'
      });
    },
    //点击隐私政策
    goyszc() {
      this.$router.push({
        path: '/home/yszc'
      });
    },
    //获取验证码
    getcode() {
      sendCode({
        phone: this.phone
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.codeyes = true;
          this.timedel();
          $Toast('验证码已发送');
        }
      });
    },
    timedel() {
      this.timedemo = setInterval(() => {
        this.time--;
        if (this.time == 0) {
          console.log('倒计时结束');
          this.codeyes = false;
          clearInterval(this.timedemo);
        }
      }, 1000);
    },
    //倒计时结束
    onFinish() {
      console.log('倒计时结束了');
    }
  }
};
//判断验证码是否发送成功
// if(localStorage.getItem('dzmmtoken')){
//   $Toast('有用户信息直接登录')
// }